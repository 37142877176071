import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * banner list - 分页查询
 * @param params
 */
export function findInvoicingFailure(params) {
  return request({
    url: envInfo.bgApp.customerlegoInvoicePath + '/invoiceManage/queryCancelInvoiceList',
    method: 'get',
    params,
  });
}

/**
 *
 * @param params  短信发送
 */
export function sendingMessage(params) {
  return request({
    url: envInfo.bgApp.customerlegoInvoicePath + '/invoice/sendMessage',
    method: 'post',
    data: params,
  });
}

/**
 *
 * @param params  删除发票记录
 */
export function deleteInvoice(params) {
  return request({
    url: envInfo.bgApp.customerlegoInvoicePath + '/invoice/delete',
    method: 'post',
    data: params,
  });
}

/**
 *
 * @param params  重试开票
 */
export function retryingInvoicing(params) {
  return request({
    url: envInfo.bgApp.customerlegoInvoicePath + '/invoiceManage/reDrawBill',
    method: 'post',
    data: params,
  });
}